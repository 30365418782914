<template>
  <div>
    <div class="content py-3">
      <div class="row">
        <div class="col-12" v-for="versandart in versandarten" :key="versandart.id">
          <div class="shipping-method box box-auswahl" v-bind:class="{
            'box-auswahl-selected': bestellung.versandartid === versandart.id,
          }" @click="changeVersandart(versandart.id)">
            <h4>{{ versandart.titel }}</h4>
            <p><span v-html="versandart.beschreibung"></span></p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 mt-3">
          <p>
            Mehr Informationen zu den Versandkosten siehe hier:
            <router-link to="/about/versandinformationen">Versandkosten</router-link>
          </p>
        </div>
      </div>
    </div>
    <div class="box-footer d-flex justify-content-between flex-lg-row">
      <div class="left">
        <router-link :to="{ name: 'BestellungAdresse' }" class="btn btn-outline-secondary"><i
            class="fa fa-chevron-left"></i> Zur&uuml;ck</router-link>
      </div>
      <div class="right">
        <router-link :to="{ name: 'BestellungZahlmethode' }" class="btn btn-primary">
          Weiter <i class="fa fa-chevron-right"></i>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import { standardfilters } from "@/mixins/standard_filters.js";
import {
  serviceSaveVersandart,
  serviceLoadBestellung,
} from "@/api/bestellung.api.js";

export default {
  name: "ShopCheckoutLiefermethode",
  mixins: [standardfilters],
  mounted() {
    this.$store.dispatch("providingHashvalues").then(async r => {
      if (r) {
        serviceLoadBestellung(this.$store.getters.getWarenkorbHash, this.$store.getters.getKundenHash).then(response => {
          if (response.data.successful) {
            this.bestellung.versandartid = response.data.bestellung.versandartid;
            this.$store.commit(
              "bestellung/setVersandartId",
              response.data.bestellung.versandartid
            );
          }
        })
      }
    });
  },
  methods: {
    async changeVersandart(versandartidNeu) {
      this.bestellung.versandartid = versandartidNeu;
      try {
        await serviceSaveVersandart(
          this.bestellung.versandartid,
          this.$store.getters.getWarenkorbHash,
          this.$store.getters.getKundenHash
        );
        this.$store.commit(
          "bestellung/setVersandartId",
          this.bestellung.versandartid
        );
      } catch (error) {
        console.log(
          "ShopCheckoutLiefermethode changeVersandart ERROR: " + error
        );
      }
    },
  },
  data: function () {
    return {
      bestellung: {
        versandartid: null,
      },
    };
  },
  computed: {
    versandarten() {
      return this.$store.getters["keyparameter/getVersandarten"];
    },
  },
};
</script>
<style scoped>
.nav-link {
  padding: 0;
}

.clickicon {
  cursor: pointer;
}

.warenkorb-summe {
  padding: 3px 12px;
}

.warenkorb-versandkosten {
  border-top: 0px solid white;
  padding: 3px 12px;
}

.warenkorb-gesamtpreis {
  border-top: 1px solid #dee2e6;
  border-bottom: 5px double #dee2e6;
  padding: 3px 12px;
}
</style>